<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <b-link
        class="ml-25"
        href="https://cogitech.pl"
        target="_blank"
      >Cogitech</b-link>
      <span class="d-none d-sm-inline-block">&nbsp;dla ArtPM &copy; {{ new Date().getFullYear() }}. Wszelkie prawa zastrzeżone.</span>
    </span>
    <br><br>
    <center><img src="https://artpm-automotive.pl/wp-content/themes/adv/img/dofinansowanie.png" style="height: 300px;"></center>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
