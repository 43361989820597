var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-link',{staticClass:"brand-logo"},[_c('img',{attrs:{"src":require("@/assets/images/logo/ford-logo.png"),"alt":"logo"}})]),_c('h2',{staticClass:"text-center mb-2"},[_vm._v(" Ankieta poszkoleniowa ")]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-observer',{ref:"pollForm"},[_c('b-card',[_vm._v(" Uprzejmie prosimy o wypełnienie ankiety, zbierającej istotne dane i informacje dotyczące szkolenia, w jakim właśnie wziąłeś/wzięłaśięłaś udział. Przekazane przez Ciebie informacje stanowić będą dane źródłowe niezbędne dla oceny efektywności szkoleń prowadzonych w ramach projektu szkoleniowego. Prosimy o wypełnienie wszystkich rubryk. "),_c('hr'),_c('h3',[_vm._v("Ocena programu szkolenia i metod prowadzenia szkolenia")]),_vm._v(" Prosimy o zaznaczenie w jakim stopniu zgadzasz się z poniższymi stwierdzeniami: "),_c('b-table-simple',{staticClass:"poll-table d-none d-lg-block",attrs:{"bordered":"","striped":"","responsive":"","small":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_c('span',{staticClass:"text-primary"},[_vm._v("Skala")])]),_c('b-th',[_vm._v(" Zgadzam się ")]),_c('b-th',[_vm._v(" Częściowo się zgadzam ")]),_c('b-th',[_vm._v(" Nie zgadzam się ")])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-td',[_vm._v("Dzięki udziałowi w szkoleniu zdobyłem nową wiedzę/utrwaliłem dotychczasową oraz zdobyłem nowe umiejętności potrzebne na moim stanowisku pracy")]),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"AGREE","name":"question1"},model:{value:(_vm.question1),callback:function ($$v) {_vm.question1=$$v},expression:"question1"}})]}}])})],1),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"PARTIALLY_AGREE","name":"question1"},model:{value:(_vm.question1),callback:function ($$v) {_vm.question1=$$v},expression:"question1"}})]}}])})],1),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"DO_NOT_AGREE","name":"question1"},model:{value:(_vm.question1),callback:function ($$v) {_vm.question1=$$v},expression:"question1"}})]}}])})],1)],1),_c('b-tr',[_c('b-td',[_vm._v("Metody szkolenia, rytm pracy i środki dydaktyczne pomogły osiągnąć cele szkolenia")]),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"AGREE","name":"question2"},model:{value:(_vm.question2),callback:function ($$v) {_vm.question2=$$v},expression:"question2"}})]}}])})],1),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"PARTIALLY_AGREE","name":"question2"},model:{value:(_vm.question2),callback:function ($$v) {_vm.question2=$$v},expression:"question2"}})]}}])})],1),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"DO_NOT_AGREE","name":"question2"},model:{value:(_vm.question2),callback:function ($$v) {_vm.question2=$$v},expression:"question2"}})]}}])})],1)],1)],1)],1),_c('div',{staticClass:"poll-mobile d-lg-none"},[_c('b-form-group',{staticClass:"mt-1"},[_c('strong',[_vm._v(" Dzięki udziałowi w szkoleniu zdobyłem nową wiedzę/utrwaliłem dotychczasową oraz zdobyłem nowe umiejętności potrzebne na moim stanowisku pracy ")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"AGREE","name":"question1m"},model:{value:(_vm.question1),callback:function ($$v) {_vm.question1=$$v},expression:"question1"}},[_vm._v(" Zgadzam się ")])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"PARTIALLY_AGREE","name":"question1m"},model:{value:(_vm.question1),callback:function ($$v) {_vm.question1=$$v},expression:"question1"}},[_vm._v(" Częściowo się zgadzam ")])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"DO_NOT_AGREE","name":"question1m"},model:{value:(_vm.question1),callback:function ($$v) {_vm.question1=$$v},expression:"question1"}},[_vm._v(" Nie zgadzam się ")])]}}])})],1),_c('b-form-group',{staticClass:"mt-1"},[_c('strong',[_vm._v(" Metody szkolenia, rytm pracy i środki dydaktyczne pomogły osiągnąć cele szkolenia ")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"AGREE","name":"question2m"},model:{value:(_vm.question2),callback:function ($$v) {_vm.question2=$$v},expression:"question2"}},[_vm._v(" Zgadzam się ")])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"PARTIALLY_AGREE","name":"question2m"},model:{value:(_vm.question2),callback:function ($$v) {_vm.question2=$$v},expression:"question2"}},[_vm._v(" Częściowo się zgadzam ")])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"DO_NOT_AGREE","name":"question2m"},model:{value:(_vm.question2),callback:function ($$v) {_vm.question2=$$v},expression:"question2"}},[_vm._v(" Nie zgadzam się ")])]}}])})],1),_c('hr')],1),_c('h3',[_vm._v("Ocena pracy trenera")]),_vm._v(" Prosimy o zaznaczenie w jakim stopniu zgadzasz się z poniższymi stwierdzeniami: "),_c('b-table-simple',{staticClass:"poll-table d-none d-lg-block",attrs:{"bordered":"","striped":"","responsive":"","small":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_c('span',{staticClass:"text-primary"},[_vm._v("Skala")])]),_c('b-th',[_vm._v(" Zgadzam się ")]),_c('b-th',[_vm._v(" Częściowo się zgadzam ")]),_c('b-th',[_vm._v(" Nie zgadzam się ")])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-td',[_vm._v("Doświadczenie trenera było adekwatne do tematu szkolenia")]),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"AGREE","name":"question3"},model:{value:(_vm.question3),callback:function ($$v) {_vm.question3=$$v},expression:"question3"}})]}}])})],1),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"PARTIALLY_AGREE","name":"question3"},model:{value:(_vm.question3),callback:function ($$v) {_vm.question3=$$v},expression:"question3"}})]}}])})],1),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"DO_NOT_AGREE","name":"question3"},model:{value:(_vm.question3),callback:function ($$v) {_vm.question3=$$v},expression:"question3"}})]}}])})],1)],1),_c('b-tr',[_c('b-td',[_vm._v("Postawa trenera była przyjazna, profesjonalna i zaangażowana")]),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"AGREE","name":"question4"},model:{value:(_vm.question4),callback:function ($$v) {_vm.question4=$$v},expression:"question4"}})]}}])})],1),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"PARTIALLY_AGREE","name":"question4"},model:{value:(_vm.question4),callback:function ($$v) {_vm.question4=$$v},expression:"question4"}})]}}])})],1),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"DO_NOT_AGREE","name":"question4"},model:{value:(_vm.question4),callback:function ($$v) {_vm.question4=$$v},expression:"question4"}})]}}])})],1)],1),_c('b-tr',[_c('b-td',[_vm._v("Sposób przekazywania wiedzy, prowadzenia dyskusji i odpowiadania na pytania sprzyjał atmosferze i osiągnięciu celu szkolenia")]),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"AGREE","name":"question5"},model:{value:(_vm.question5),callback:function ($$v) {_vm.question5=$$v},expression:"question5"}})]}}])})],1),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"PARTIALLY_AGREE","name":"question5"},model:{value:(_vm.question5),callback:function ($$v) {_vm.question5=$$v},expression:"question5"}})]}}])})],1),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"DO_NOT_AGREE","name":"question5"},model:{value:(_vm.question5),callback:function ($$v) {_vm.question5=$$v},expression:"question5"}})]}}])})],1)],1)],1)],1),_c('div',{staticClass:"poll-mobile d-lg-none"},[_c('b-form-group',{staticClass:"mt-1"},[_c('strong',[_vm._v(" Doświadczenie trenera było adekwatne do tematu szkolenia ")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"AGREE","name":"question3m"},model:{value:(_vm.question3),callback:function ($$v) {_vm.question3=$$v},expression:"question3"}},[_vm._v(" Zgadzam się ")])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"PARTIALLY_AGREE","name":"question3m"},model:{value:(_vm.question3),callback:function ($$v) {_vm.question3=$$v},expression:"question3"}},[_vm._v(" Częściowo się zgadzam ")])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"DO_NOT_AGREE","name":"question3m"},model:{value:(_vm.question3),callback:function ($$v) {_vm.question3=$$v},expression:"question3"}},[_vm._v(" Nie zgadzam się ")])]}}])})],1),_c('b-form-group',{staticClass:"mt-1"},[_c('strong',[_vm._v(" Postawa trenera była przyjazna, profesjonalna i zaangażowana ")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"AGREE","name":"question4m"},model:{value:(_vm.question4),callback:function ($$v) {_vm.question4=$$v},expression:"question4"}},[_vm._v(" Zgadzam się ")])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"PARTIALLY_AGREE","name":"question4m"},model:{value:(_vm.question4),callback:function ($$v) {_vm.question4=$$v},expression:"question4"}},[_vm._v(" Częściowo się zgadzam ")])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"DO_NOT_AGREE","name":"question4m"},model:{value:(_vm.question4),callback:function ($$v) {_vm.question4=$$v},expression:"question4"}},[_vm._v(" Nie zgadzam się ")])]}}])})],1),_c('b-form-group',{staticClass:"mt-1"},[_c('strong',[_vm._v(" Sposób przekazywania wiedzy, prowadzenia dyskusji i odpowiadania na pytania sprzyjał atmosferze i osiągnięciu celu szkolenia ")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"AGREE","name":"question5m"},model:{value:(_vm.question5),callback:function ($$v) {_vm.question5=$$v},expression:"question5"}},[_vm._v(" Zgadzam się ")])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"PARTIALLY_AGREE","name":"question5m"},model:{value:(_vm.question5),callback:function ($$v) {_vm.question5=$$v},expression:"question5"}},[_vm._v(" Częściowo się zgadzam ")])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"DO_NOT_AGREE","name":"question5m"},model:{value:(_vm.question5),callback:function ($$v) {_vm.question5=$$v},expression:"question5"}},[_vm._v(" Nie zgadzam się ")])]}}])})],1),_c('hr')],1),_c('h3',[_vm._v(" W jakim stopniu szkolenie, w którym uczestniczyłeś/aś będzie miało wpływ na następujące aspekty? ")]),_c('b-table-simple',{staticClass:"poll-table d-none d-lg-block",attrs:{"bordered":"","striped":"","responsive":"","small":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_c('span',{staticClass:"text-primary"},[_vm._v("Skala")])]),_c('b-th',[_vm._v(" Duży wpływ")]),_c('b-th',[_vm._v(" Umiarkowany wpływ ")]),_c('b-th',[_vm._v(" Mały wpływ")])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-td',[_vm._v("Jakość Twojej pracy")]),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"BIG_IMPACT","name":"question6"},model:{value:(_vm.question6),callback:function ($$v) {_vm.question6=$$v},expression:"question6"}})]}}])})],1),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"MODERATE_IMPACT","name":"question6"},model:{value:(_vm.question6),callback:function ($$v) {_vm.question6=$$v},expression:"question6"}})]}}])})],1),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"SMALL_IMPACT","name":"question6"},model:{value:(_vm.question6),callback:function ($$v) {_vm.question6=$$v},expression:"question6"}})]}}])})],1)],1),_c('b-tr',[_c('b-td',[_vm._v("Efektywność Twojej pracy - możliwość osiągnięcia lepszych efektów")]),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"BIG_IMPACT","name":"question7"},model:{value:(_vm.question7),callback:function ($$v) {_vm.question7=$$v},expression:"question7"}})]}}])})],1),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"MODERATE_IMPACT","name":"question7"},model:{value:(_vm.question7),callback:function ($$v) {_vm.question7=$$v},expression:"question7"}})]}}])})],1),_c('b-td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"SMALL_IMPACT","name":"question7"},model:{value:(_vm.question7),callback:function ($$v) {_vm.question7=$$v},expression:"question7"}})]}}])})],1)],1)],1)],1),_c('div',{staticClass:"poll-mobile d-lg-none"},[_c('b-form-group',{staticClass:"mt-1"},[_c('strong',[_vm._v(" Jakość Twojej pracy ")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"BIG_IMPACT","name":"question6m"},model:{value:(_vm.question6),callback:function ($$v) {_vm.question6=$$v},expression:"question6"}},[_vm._v(" Duży wpływ ")])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"MODERATE_IMPACT","name":"question6m"},model:{value:(_vm.question6),callback:function ($$v) {_vm.question6=$$v},expression:"question6"}},[_vm._v(" Umiarkowany wpływ ")])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"SMALL_IMPACT","name":"question6m"},model:{value:(_vm.question6),callback:function ($$v) {_vm.question6=$$v},expression:"question6"}},[_vm._v(" Mały wpływ ")])]}}])})],1),_c('b-form-group',{staticClass:"mt-1"},[_c('strong',[_vm._v(" Efektywność Twojej pracy - możliwość osiągnięcia lepszych efektów ")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"BIG_IMPACT","name":"question7m"},model:{value:(_vm.question7),callback:function ($$v) {_vm.question7=$$v},expression:"question7"}},[_vm._v(" Duży wpływ ")])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"MODERATE_IMPACT","name":"question7m"},model:{value:(_vm.question7),callback:function ($$v) {_vm.question7=$$v},expression:"question7"}},[_vm._v(" Umiarkowany wpływ ")])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"SMALL_IMPACT","name":"question7m"},model:{value:(_vm.question7),callback:function ($$v) {_vm.question7=$$v},expression:"question7"}},[_vm._v(" Mały wpływ ")])]}}])})],1),_c('hr')],1),_c('h3',[_vm._v("Referencje")]),_c('b-form-group',{staticClass:"mt-1"},[_c('strong',[_vm._v("Czy poleciłbyś to szkolenie kolegom / koleżankom?")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"DEFINITELY_YES","name":"question8"},model:{value:(_vm.question8),callback:function ($$v) {_vm.question8=$$v},expression:"question8"}},[_vm._v(" Zdecydowanie tak ")])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"YES","name":"question8"},model:{value:(_vm.question8),callback:function ($$v) {_vm.question8=$$v},expression:"question8"}},[_vm._v(" Tak ")])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"NO","name":"question8"},model:{value:(_vm.question8),callback:function ($$v) {_vm.question8=$$v},expression:"question8"}},[_vm._v(" Nie ")])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"required":"","state":errors.length > 0 ? false : null,"value":"DEFINITELY_NO","name":"question8"},model:{value:(_vm.question8),callback:function ($$v) {_vm.question8=$$v},expression:"question8"}},[_vm._v(" Zdecydowanie nie ")])]}}])})],1),_c('hr'),_c('h3',[_vm._v("Twoja opinia o szkoleniu")]),_c('b-form-textarea',{staticClass:"mt-1",staticStyle:{"height":"10em"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_c('b-button',{staticClass:"mt-2",attrs:{"block":"","type":"submit","variant":"gradient-primary","disabled":_vm.loading}},[_vm._v(" Wyślij ")])],1)],1)],1),_c('app-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }